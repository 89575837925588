function isVimeoLink(source) {
  if (!source) return false;
  // Regular expression pattern to match Vimeo video URLs
  const vimeoPattern = /^(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/(video\/)?(\d+)(\/[^\s]*)?$/;
  const id = source?.match(vimeoPattern)?.[5];
  // console.log({ id });

  if (id) {
    return `https://player.vimeo.com/video/${id}`;
    // https://vimeo.com/920314562
  }

  if (!isNaN(source)) {
    return `https://player.vimeo.com/video/${source}`;
  }
  return vimeoPattern.test(source);
}

export default isVimeoLink;
