import React from "react";

const Video = ({ captions, poster, source, qualities, isPremium, className = "", reference, ...props }) => {
  // console.log({ source });
  return (
    <>
      <video crossOrigin className={className} id="player" data-poster={poster || ""} ref={reference} src={source} {...props} style={{ width: "100%", maxWidth: "100%" }}>
        {/* Captions / Subtitles */}
        {isPremium && (
          <>
            {Array.isArray(captions) &&
              captions.map((caption, index) => {
                if (!caption.caption_file) {
                  return;
                }
                const info = caption.label.split("/");
                return <track key={index} kind="captions" srcLang={info[1] || " "} label={info[0] || "no label"} src={caption.caption_file} />;
              })}

            {/* Qualities */}
            {!["m3u8", "mpd"].includes(source?.split(".").pop()) && (
              <>
                <source src={source} size={720} type={`video/${source?.split(".").pop()}`} />
                {Array.isArray(qualities) &&
                  qualities.map((item, index) => {
                    // return <source key={index} src={item.video_file} size={item.size} type="video/mp4" />;
                  })}
              </>
            )}
          </>
        )}
      </video>
      {props["data-poster"] && <div className="preload_poster" style={{ background: `url(${props["data-poster"]})` }}></div>}
    </>
  );
};

export default Video;
